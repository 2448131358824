.remote2 {

    background: -moz-linear-gradient(90deg, #5C101A 0, #270B99 100%);/* FF3.6+ */
    background: -webkit-linear-gradient(90deg, #5C101A 0, #270B99 100%);/* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(90deg, #5C101A 0, #270B99 100%);/* Opera 11.10+ */
    background: -ms-linear-gradient(90deg, #5C101A 0, #270B99 100%);/* IE10+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1301FE', endColorstr='#F4F60C', GradientType='1'); /* for IE */
    background: linear-gradient(90deg, #5C101A 0, #270B99 100%);/* W3C */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}